import React from "react"
import { Redirect } from "react-router-dom"

import Dashboard from "../pages/Dashboard/index"

import PagesMaintenance from "../pages/Utility/pages-maintenance"
// import PagesComingsoon from "../pages/Utility/pages-comingsoon"

import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ChangePassword from "../pages/Authentication/ChangePassword"

import LockScreen from "../pages/Authentication/LockScreen"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

import Headings from "../pages/Headings/Headings"
import EditHeading from "../pages/Headings/EditHeading"
import CreateHeading from "../pages/Headings/CreateHeading"

import Contractors from "../pages/Contractor/Contractors"
import AddContractor from "../pages/Contractor/AddContractor"
import EditContractor from "../pages/Contractor/EditContractor"

import EndorsmentListing from "../pages/EndorsmentContractor/EndorsmentListing"
import DirectoryPartenr from "../pages/Directory Partner/DirectoryPartenr"


import Campaigns from "../pages/Campaign/Campaigns"
import AddCampaign from "../pages/Campaign/AddCampaign"
import EditCampaign from "../pages/Campaign/EditCampaign"
import EditPendingCampaign from "../pages/Campaign/EditPendingCampaign"

import FeedbackQuestions from "../pages/Feedback/FeedbackQuestions"
import ReviewAnswers from "../pages/Feedback/ReviewAnswers"


import InitialScreening from "../pages/InitialScreening/InitialScreening"
import EditInitialScreening from "../pages/InitialScreening/EditInitialScreening"

import EmailForms from "../pages/EmailForms/EmailForms"

import ContractorsReview from "../pages/ContractorsReview/ContractorsReview"
import IndividualView from "../pages/ContractorsReview/IndividualView"
import CumulativeView from "../pages/ContractorsReview/CumulativeView"

import Customers from "../pages/Customers/Customers"
import EditCustomer from "../pages/Customers/EditCustomer"

import Users from "../pages/Users/Users"
import NewUser from "../pages/Users/NewUser"
import EditUser from "../pages/Users/EditUser"


// Reports
import ZipSoldReport from "../pages/Reports/ZipSoldReport"
import ZoneSold from "../pages/Reports/ZoneSold"
import FinancialReport from "../pages/Reports/FinancialReport"
import MasterSoldReport from "../pages/Reports/MasterSoldReport"
import CallRepots from "../pages/Reports/CallRepots"

import UsersReports from "../pages/Reports/UsersReports"
import CampaignsReport from "../pages/Reports/CampaignsReport"
import ContractorsReport from "../pages/Reports/ContractorsReport"
import TotalUsersReport from "../pages/Reports/TotalUsersReport"
import ContractorReferReport from "../pages/Reports/ContractorReferReport"

import CallNotes from "../pages/Calls/CallNotes"
import EditCallNotes from "../pages/Calls/EditCallNotes"


import CallArchive from "../pages/Reports/CallArchive"

import CallNotesCronJobs from "../pages/CronJobs.js/CallNotesCronJobs"
import CallNotesArchiveCronJobs from "../pages/CronJobs.js/CallNotesArchiveCronJobs"


import Highlights from "../pages/Config/Highlights/Highlights"
import EditHighlight from "../pages/Config/Highlights/EditHighlight"
import AddHighlight from "../pages/Config/Highlights/AddHighlight"

import LocationPages from "../pages/Config/CategoryPage/LocationPages"
import CreatePage from "../pages/Config/CategoryPage/CreatePage"
import EditPage from "../pages/Config/CategoryPage/EditPage"

import ZipcodePages from "../pages/Config/ZipPages/ZipcodePages"
import EditZipPage from "../pages/Config/ZipPages/EditZipPage"
import CreateZipcodePage from "../pages/Config/ZipPages/CreateZipcodePage"



import Zipcodes from "../pages/Config/Zipcodes/Zipcodes"
import AddNewZipCode from "../pages/Config/Zipcodes/AddNewZipCode"


import Teams from "../pages/Config/Teams/Teams"
import Addteam from "../pages/Config/Teams/Addteam"
import EditMember from "../pages/Config/Teams/EditMember"


import TermsCondition from "../pages/Config/TermsCondition"

import ScreeningProcess from "../pages/Config/ScreeningProcess"
import PrivacyPolicy from "../pages/Config/PrivacyPolicy"
import CodeOfEthics from "../pages/Config/CodeOfEthics"
import Ministry from "../pages/Config/Ministry"


import Pages from "../pages/Config/ManagePagesMeta/Pages" 
import EditPageMeta from "../pages/Config/ManagePagesMeta/EditPageMeta"

import HereFromUs from "../pages/Config/HereFromUs"
import UrlRedirects from "../pages/Config/UrlRedirects"

// Analytics 
import PagesAnalytics from "../pages/Analytics/PagesAnalytics"
import FormsAnalytics from "../pages/Analytics/FormsAnalytics"
import ContractorDetailReport from "../pages/Analytics/Contractor/ContractorDetailReport"

import AnalyticsDashboard from "../pages/Analytics/Dashboard"

import CategoryAnalytics from "../pages/Analytics/Category/CategoryAnalytics"
import CategoryDetailAnalytics from "../pages/Analytics/Category/CategoryDetailAnalytics"

import ZipcodeAnalytics from "../pages/Analytics/Zipcode/ZipcodeAnalytics"
import ZipcodeDetailAnalytics from "../pages/Analytics/Zipcode/ZipcodeDetailAnalytics"

import WebsiteClicks from "../pages/Analytics/Contractor/WebsiteClicks"

import ContractorCalls from "../pages/Analytics/Contractor/ContractorCalls"

import ContractorProfileView from "../pages/Analytics/Contractor/ContractorProfileView"

import EmailToContractor from "../pages/Analytics/Contractor/EmailToContractor"

import ContractorRegistraction from "../pages/Analytics/Contractor/ContractorRegistraction"

import GuranteeRegistraction from "../pages/Analytics/Contractor/GuranteeRegistraction"

import ContractorFeedback from "../pages/Analytics/Contractor/ContractorFeedback"

import Editor from "../pages/Headings/CK-Editor"

import States from "../pages/Config/Locations/States"
import County from "../pages/Config/Locations/County"
import Locations from "../pages/Config/Locations/Locations"
import AddNewLocation from "../pages/Config/Locations/AddNewLocation"
import ManageCities from "../pages/Config/Locations/ManageCities"

const userRoutes = [

  { path: "/editor", component: Editor },
  { path: "/dashboard", component: Dashboard },
  
  { path: "/profile", component: UserProfile },

  { path: "/categories", component: Headings }, 
  { path: "/category/:id", component: EditHeading }, 
  { path: "/new-category", component: CreateHeading }, 
  

  { path: "/contractors", component: Contractors }, 
  { path: "/new-contractor", component: AddContractor }, 
  { path: "/contractor/:id", component: EditContractor }, 

  { path: "/endorsment-contractors/", component: EndorsmentListing }, 
  { path: "/directory-contractors/", component: DirectoryPartenr }, 




  

  { path: "/campaigns", component: Campaigns }, 
  { path: "/new-campaign", component: AddCampaign },
  { path: "/edit-campaign/:id", component: EditCampaign },
  { path: "/edit-pending-campaign/:id", component: EditPendingCampaign },

  { path: "/feedback-questions", component: FeedbackQuestions },
  { path: "/feedback-answers/:id", component: ReviewAnswers },

  { path: "/initial-screening", component: InitialScreening },
  { path: "/edit-initial-screening/:id", component: EditInitialScreening },

  { path: "/email-forms", component: EmailForms },

  { path: "/contractors-feedback", component: ContractorsReview },
  { path: "/individual-view/:id", component: IndividualView },
  { path: "/cumulative-view/:id", component: CumulativeView },

  { path: "/customers", component: Customers },
  { path: "/edit-registered-user/:id", component: EditCustomer },

  { path: "/admins", component: Users }, 
  { path: "/new-user", component: NewUser }, 
  { path: "/edit-admin/:id", component: EditUser }, 


  { path: "/zip-sold-report", component: ZipSoldReport }, 
  { path: "/zone-sold-report", component: ZoneSold },
  { path: "/financial-report", component: FinancialReport },
  { path: "/master-sold-report", component: MasterSoldReport },
  { path: "/users-report", component: UsersReports },
  { path: "/media-partner-referral-report", component: TotalUsersReport },
  { path: "/contractor-referral-report", component: ContractorReferReport },
  { path: "/campaigns-report", component: CampaignsReport },
  { path: "/contractors-report", component: ContractorsReport },
  
  
  { path: "/call-archive", component: CallArchive },
  { path: "/call-report", component: CallRepots },
  { path: "/comm-notes", component: CallNotes },
  { path: "/edit-comm-notes/:id", component: EditCallNotes },


  { path: "/comm-notes-cron-job", component: CallNotesCronJobs },
  { path: "/comm-notes-archive-cron-job", component: CallNotesArchiveCronJobs },


  { path: "/highlights", component: Highlights },
  { path: "/highlight/:id", component: EditHighlight }, 
  { path: "/new-highlight", component: AddHighlight }, 

  { path: "/location-pages", component: LocationPages },
  { path: "/new-page", component: CreatePage }, 
  { path: "/edit-page/:id", component: EditPage }, 


  { path: "/zipcode-pages", component: ZipcodePages },
  { path: "/edit-zip-page/:id", component: EditZipPage }, 
  { path: "/new-zipcode-page", component: CreateZipcodePage }, 

  { path: "/states", component: States },
  { path: "/counties", component: County },
  { path: "/locations", component: Locations },
  { path: "/new-location", component: AddNewLocation },
  { path: "/edit-location/:id", component: ManageCities },


  { path: "/zipcodes", component: Zipcodes },
  { path: "/new-zipcode", component: AddNewZipCode }, 


  { path: "/team", component: Teams },
  { path: "/new-team-member", component: Addteam },
  { path: "/edit-team-member/:id", component: EditMember },


  { path: "/terms-conditions", component: TermsCondition },
  { path: "/screening-process", component: ScreeningProcess },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/code-of-ethics", component: CodeOfEthics },
  { path: "/ministry", component: Ministry },


  { path: "/pages", component: Pages },
  { path: "/edit-page-meta/:slug", component: EditPageMeta },  
  
  { path: "/here-from-us", component: HereFromUs },
  { path: "/url-redirects", component: UrlRedirects },

  
  { path: "/analytics/pages-analytics", component: PagesAnalytics },
  { path: "/analytics/forms-analytics", component: FormsAnalytics },
  

  { path: "/analytics/category-search", component: CategoryAnalytics },
  { path: "/analytics/category-search-detail/:id", component: CategoryDetailAnalytics },

  { path: "/analytics/zipcode-search", component: ZipcodeAnalytics },
  { path: "/analytics/zipcode-search-detail/:zipcode", component: ZipcodeDetailAnalytics },

  { path: "/analytics/contractor-website-clicks", component: WebsiteClicks },

  { path: "/analytics/contractor-received-calls", component: ContractorCalls },

  { path: "/analytics/contractor-profile-views", component: ContractorProfileView },

  { path: "/analytics/email-to-contractor", component: EmailToContractor },

  { path: "/analytics/contractor-registraction", component: ContractorRegistraction },

  { path: "/analytics/gurantee-registraction", component: GuranteeRegistraction },

  { path: "/analytics/contractor-feedback", component: ContractorFeedback },

  { path: "/analytics", component: AnalyticsDashboard },

  
  
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/password-reset/:token", component: ChangePassword },
  
  { path: "/pages-maintenance", component: PagesMaintenance },

  { path: "/not-found", component: Pages404 },
  { path: "/pages-500", component: Pages500 },


  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/analytics/contractor-detail/:slug", component: ContractorDetailReport },

]

export { userRoutes, authRoutes }